define("@frontile/forms/components/form-checkbox-group", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _object, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormField
    @size={{@size}}
    class={{use-frontile-class "form-checkbox-group" @size (if @isInline "inline") class=@containerClass}}
    role="group"
    ...attributes
    as |f|
  >
    {{#if @label}}
      <f.Label
        class={{use-frontile-class "form-checkbox-group" @size (if @isInline "inline") part="label"}}
      >
        {{@label}}
      </f.Label>
    {{/if}}
  
    {{#if @hint}}
      <f.Hint
        class={{use-frontile-class "form-checkbox-group" @size (if @isInline "inline") part="hint"}}
      >
        {{@hint}}
      </f.Hint>
    {{/if}}
  
    {{yield (component "form-checkbox"
              privateContainerClass=(use-frontile-class "form-checkbox-group" @size (if @isInline "inline") part="form-checkbox")
              _parentOnChange=this.handleChange
              size=@size
            )
            (hash onChange=this.handleChange)
    }}
  
    {{#if this.showErrorFeedback}}
      <f.Feedback
        class={{use-frontile-class "form-checkbox-group" @size (if @isInline "inline") part="feedback"}}
        @errors={{@errors}}
      />
    {{/if}}
  </FormField>
  
  */
  {
    "id": "papnH6u5",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"form-checkbox-group\",[30,1],[52,[30,2],\"inline\"]],[[\"class\"],[[30,3]]]]],[24,\"role\",\"group\"],[17,4]],[[\"@size\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[8,[30,5,[\"Label\"]],[[16,0,[28,[37,1],[\"form-checkbox-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"label\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,6]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,7],[[[1,\"    \"],[8,[30,5,[\"Hint\"]],[[16,0,[28,[37,1],[\"form-checkbox-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"hint\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,7]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,9,[[50,\"form-checkbox\",0,null,[[\"privateContainerClass\",\"_parentOnChange\",\"size\"],[[28,[37,1],[\"form-checkbox-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"form-checkbox\"]]],[30,0,[\"handleChange\"]],[30,1]]]],[28,[37,5],null,[[\"onChange\"],[[30,0,[\"handleChange\"]]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showErrorFeedback\"]],[[[1,\"    \"],[8,[30,5,[\"Feedback\"]],[[16,0,[28,[37,1],[\"form-checkbox-group\",[30,1],[52,[30,2],\"inline\"]],[[\"part\"],[\"feedback\"]]]]],[[\"@errors\"],[[30,8]]],null],[1,\"\\n\"]],[]],null]],[5]]]]],[1,\"\\n\"]],[\"@size\",\"@isInline\",\"@containerClass\",\"&attrs\",\"f\",\"@label\",\"@hint\",\"@errors\",\"&default\"],false,[\"form-field\",\"use-frontile-class\",\"if\",\"yield\",\"component\",\"hash\"]]",
    "moduleName": "@frontile/forms/components/form-checkbox-group.hbs",
    "isStrictMode": false
  });
  let FormCheckboxGroup = _exports.default = (_class = class FormCheckboxGroup extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "shouldShowErrorFeedback", _descriptor, this);
    }
    get showErrorFeedback() {
      if (this.args.hasError === false) {
        return false;
      }
      if ((this.args.showError || this.args.hasSubmitted || this.shouldShowErrorFeedback) && this.args.errors && this.args.errors.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    handleChange(value, event) {
      this.shouldShowErrorFeedback = true;
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldShowErrorFeedback", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormCheckboxGroup);
});