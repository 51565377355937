define("ember-svg-jar/inlined/tidal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"12\" d=\"M71 81l25-25 25 25m-50 0l25 25M71 81l-25 25-25-25 25-25 25 25zm25 25l25-25m-25 25l-25 25 25 25 25-25-25-25zm25-25l25-25 25 25-25 25-25-25z\"/>",
    "attrs": {
      "width": "800",
      "height": "800",
      "viewBox": "0 0 192 192",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none"
    }
  };
});