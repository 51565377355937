define("@frontile/core/components/collapsible/index", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/test-waiters", "@frontile/core/utils/safe-styles", "@ember/template-factory"], function (_exports, _component, _component2, _object, _testWaiters, _safeStyles, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    style={{this.styles}}
    ...attributes
    {{did-update this.update @isOpen}}
    {{on "transitionend" this.onTransitionEnd}}
  >
    {{yield}}
  </div>
  */
  {
    "id": "0258Va5B",
    "block": "[[[11,0],[16,5,[30,0,[\"styles\"]]],[17,1],[4,[38,0],[[30,0,[\"update\"]],[30,2]],null],[4,[38,1],[\"transitionend\",[30,0,[\"onTransitionEnd\"]]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@isOpen\",\"&default\"],false,[\"did-update\",\"on\",\"yield\"]]",
    "moduleName": "@frontile/core/components/collapsible/index.hbs",
    "isStrictMode": false
  });
  const waiter = (0, _testWaiters.buildWaiter)('@frontile/core:collapsible');
  let Collapsible = _exports.default = (_class = class Collapsible extends _component2.default {
    // Internal value to track if open or not

    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "isInitiallyOpen", false);
      _defineProperty(this, "waiterToken", void 0);
      _defineProperty(this, "isCurrentlyOpen", false);
      if (this.args.isOpen) {
        this.isInitiallyOpen = true;
        this.isCurrentlyOpen = true;
      }
    }
    get styles() {
      let styles = {};
      if (!this.isInitiallyOpen) {
        styles = {
          ...styles,
          height: this.args.initialHeight || 0,
          opacity: this.args.initialHeight ? '1' : '0'
        };
      }
      if (this.args.initialHeight || !this.isInitiallyOpen) {
        styles = {
          ...styles,
          overflow: 'hidden'
        };
      }
      return (0, _safeStyles.default)(styles);
    }
    update(element, _ref) {
      let [isOpen] = _ref;
      if (this.isCurrentlyOpen !== !!isOpen) {
        this.waiterToken = waiter.beginAsync();
      }
      if (isOpen) {
        this.expand(element);
      } else {
        this.contract(element);
      }
    }
    onTransitionEnd(event) {
      if ((event.propertyName === 'height' || event.propertyName == 'opacity') && this.args.isOpen) {
        event.target.style.height = 'auto';
        event.target.style.overflow = '';
      }
      if (this.waiterToken) {
        // when is opened, wait for height transition to finish
        // when is opened, wait for opacity transition to finish at 1
        // when closed, wait for opacity transition to finish at 0
        // when closed and has initialHeight, wait for height transition to finish
        if (this.args.isOpen && event.propertyName === 'height' || !this.args.isOpen && event.propertyName === 'opacity' && event.target.style.opacity == '0' || this.args.isOpen && event.propertyName === 'opacity' && event.target.style.opacity == '1' || !this.args.isOpen && this.args.initialHeight && event.propertyName === 'height') {
          waiter.endAsync(this.waiterToken);
        }
      }
    }
    heightTransition(duration) {
      return "height ".concat(duration, "s cubic-bezier(0.4, 0, 0.2, 1) 0ms");
    }
    opacityTransition(duration) {
      return "opacity ".concat(duration, "s ease-in-out 0ms");
    }
    expand(element) {
      this.isCurrentlyOpen = true;
      element.style.transition = [this.heightTransition(0.4), this.opacityTransition(0.3)].join(', ');
      element.style.overflow = 'hidden';
      const height = element.scrollHeight;
      window.requestAnimationFrame(() => {
        element.style.opacity = '1';
        element.style.height = "".concat(height, "px");
      });
    }
    contract(element) {
      this.isCurrentlyOpen = false;
      const height = element.scrollHeight;
      element.style.transition = '';
      element.style.overflow = 'hidden';
      window.requestAnimationFrame(() => {
        element.style.height = "".concat(height, "px");
        element.style.transition = [this.heightTransition(0.2), this.opacityTransition(0.3)].join(', ');
        window.requestAnimationFrame(() => {
          if (!this.args.initialHeight) {
            element.style.opacity = '0';
          }
          element.style.height = this.args.initialHeight || '0';
        });
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTransitionEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onTransitionEnd"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Collapsible);
});