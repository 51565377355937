define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter x=\"-4.2%\" y=\"-6.5%\" width=\"108.9%\" height=\"114%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feOffset in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"6\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0.719042304 0 0 0 0 0.719042304 0 0 0 0 0.719042304 0 0 0 0.5 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g filter=\"url(#a)\" transform=\"translate(-602 -13)\" fill=\"#4C637D\" fill-rule=\"nonzero\"><path d=\"M623.654 31l3.98-3.98a1.251 1.251 0 000-1.77l-.885-.884a1.251 1.251 0 00-1.769 0l-3.98 3.98-3.98-3.98a1.251 1.251 0 00-1.77 0l-.884.885a1.251 1.251 0 000 1.769l3.98 3.98-3.98 3.98a1.251 1.251 0 000 1.77l.885.884c.488.488 1.28.488 1.769 0l3.98-3.98 3.98 3.98c.488.488 1.28.488 1.77 0l.884-.885a1.251 1.251 0 000-1.769l-3.98-3.98z\"/></g>",
    "attrs": {
      "width": "38",
      "height": "36",
      "viewBox": "0 0 38 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});