define("ember-svg-jar/inlined/jagged-edge-bordered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M-15 110L100 10l115 100\" fill=\"transparent\" stroke=\"#DBDBDB\" stroke-width=\"2\" vector-effect=\"non-scaling-stroke\"/>",
    "attrs": {
      "class": "jagged-edge",
      "viewBox": "0 0 200 110",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});