define("ember-svg-jar/inlined/zig-zag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M912 40l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M912 80l-76 40-76-40v40l76 40 76-40M912 0l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#FFF\" d=\"M760 40l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M760 80l-76 40-76-40v40l76 40 76-40M760 0l-76 40-76-40v40l76 40 76-40\"/><g><path fill=\"#FFF\" d=\"M608 40l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M608 80l-76 40-76-40v40l76 40 76-40M608 0l-76 40-76-40v40l76 40 76-40\"/></g><g><path fill=\"#FFF\" d=\"M456 40l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M456 80l-76 40-76-40v40l76 40 76-40M456 0l-76 40-76-40v40l76 40 76-40\"/></g><g><path fill=\"#FFF\" d=\"M304 40l-76 40-76-40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M304 80l-76 40-76-40v40l76 40 76-40M304 0l-76 40-76-40v40l76 40 76-40\"/></g><g><path fill=\"#FFF\" d=\"M152 40L76 80 0 40v40l76 40 76-40\"/><path fill=\"#D8D8D8\" d=\"M152 80l-76 40L0 80v40l76 40 76-40M152 0L76 40 0 0v40l76 40 76-40\"/></g></g>",
    "attrs": {
      "width": "912",
      "height": "160",
      "viewBox": "0 0 912 160",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});