define("ember-svg-jar/inlined/discogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Discogs</title><path d=\"M1.742 11.982c0-5.668 4.61-10.278 10.276-10.278 1.824 0 3.537.48 5.025 1.317l.814-1.488A11.914 11.914 0 0012.19.003h-.195C5.41.013.072 5.31 0 11.885v.115a11.983 11.983 0 003.775 8.72l1.185-1.28a10.249 10.249 0 01-3.218-7.459zm18.62-8.577l-1.154 1.24a10.25 10.25 0 013.088 7.337c0 5.666-4.61 10.276-10.276 10.276-1.783 0-3.46-.456-4.922-1.258l-.854 1.522A11.946 11.946 0 0012 23.998c6.626 0 12.001-5.373 12.001-12a11.977 11.977 0 00-3.638-8.593zM1.963 11.982a10.03 10.03 0 003.146 7.295l1.18-1.276a8.295 8.295 0 01-2.586-6.019c0-4.586 3.73-8.315 8.315-8.315 1.483 0 2.875.391 4.082 1.075l.835-1.526a9.973 9.973 0 00-4.917-1.289C6.475 1.925 1.963 6.437 1.963 11.982zm18.37 0c0 4.586-3.73 8.315-8.315 8.315a8.273 8.273 0 01-3.962-1.005l-.852 1.516a10.006 10.006 0 004.814 1.229c5.543 0 10.055-4.512 10.055-10.055 0-2.808-1.157-5.347-3.017-7.173l-1.183 1.274a8.282 8.282 0 012.46 5.899zm-1.948 0a6.37 6.37 0 01-6.365 6.364 6.329 6.329 0 01-3.006-.756l-.848 1.507a8.039 8.039 0 003.854.977c4.464 0 8.095-3.63 8.095-8.094 0-2.24-.914-4.27-2.39-5.738l-1.179 1.267a6.356 6.356 0 011.839 4.473zm-14.459 0c0 2.301.967 4.382 2.515 5.858l1.173-1.27a6.344 6.344 0 01-1.96-4.588 6.37 6.37 0 016.364-6.364 6.32 6.32 0 013.144.835l.83-1.517a8.055 8.055 0 00-3.974-1.048c-4.461 0-8.092 3.63-8.092 8.094zm12.53 0a4.438 4.438 0 01-4.438 4.437 4.42 4.42 0 01-2.061-.509l-.835 1.488a6.114 6.114 0 002.896.727 6.149 6.149 0 006.143-6.143 6.123 6.123 0 00-1.768-4.308l-1.162 1.25a4.43 4.43 0 011.224 3.058zm-10.581 0a6.12 6.12 0 001.888 4.425l1.157-1.25.014.014a4.419 4.419 0 01-1.355-3.187 4.436 4.436 0 014.437-4.437c.808 0 1.564.219 2.217.598l.82-1.498a6.097 6.097 0 00-3.037-.806c-3.384-.005-6.141 2.753-6.141 6.141zm6.68 0a.538.538 0 01-1.074 0 .537.537 0 111.075 0zm-3.94 0a3.4 3.4 0 116.801 0 3.4 3.4 0 01-6.8 0zm.149 0a3.256 3.256 0 003.252 3.252 3.255 3.255 0 003.254-3.252 3.253 3.253 0 10-6.506 0z\"/>",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});