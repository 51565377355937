define("ember-svg-jar/inlined/sparkle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.95 1.002a1 1 0 00-.9.682L9.46 9.459l-7.775 2.592a1 1 0 000 1.898l7.775 2.592 2.592 7.775a1 1 0 001.898 0l2.592-7.775 7.775-2.592a1 1 0 000-1.898l-7.775-2.592-2.592-7.775a1 1 0 00-1-.682zM13 5.164l1.8 5.402a1 1 0 00.634.633L20.836 13l-5.402 1.8a1 1 0 00-.633.634L13 20.836l-1.8-5.402a1 1 0 00-.634-.633L5.164 13l5.402-1.8a1 1 0 00.633-.634L13 5.164zm20.459 5.838a1 1 0 00-.91.691l-3.613 11.243-11.243 3.613a1 1 0 000 1.902l11.243 3.613 3.613 11.243a1 1 0 001.902 0l3.613-11.243 11.243-3.613a1 1 0 000-1.902l-11.243-3.613-3.613-11.243a1 1 0 00-.992-.691zm.041 4.264l2.818 8.77a1 1 0 00.647.646l8.77 2.818-8.77 2.818a1 1 0 00-.647.647l-2.818 8.77-2.818-8.77a1 1 0 00-.647-.647l-8.77-2.818 8.77-2.818a1 1 0 00.647-.647l2.818-8.77zM8.95 31.002a1 1 0 00-.9.682L6.21 37.209l-5.523 1.84a1 1 0 000 1.898l5.523 1.842 1.842 5.523a1 1 0 001.898 0l1.842-5.523 5.525-1.842a1 1 0 000-1.898l-5.525-1.84-1.842-5.525a1 1 0 00-1-.682zM9 35.164l1.05 3.15a1 1 0 00.634.633l3.152 1.051-3.152 1.05a1 1 0 00-.633.634L9 44.832l-1.05-3.15a1 1 0 00-.634-.633l-3.152-1.051 3.152-1.05a1 1 0 00.633-.634L9 35.164z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});