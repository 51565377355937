define("ember-printable-pages/services/document-data", ["exports", "@ember/service", "ember-printable-pages/util-models/report", "ember-printable-pages/util-models/chapter", "ember-printable-pages/util-models/page", "ember-printable-pages/util-models/section", "ember-printable-pages/utils/logger"], function (_exports, _service, _report, _chapter, _page, _section, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class DocumentData extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "reportsMap", {});
    }
    register(id) {
      window.documentData = this;
      (0, _logger.log)("<service:document-data> register(".concat(id, ")"));
      let report = new _report.default();
      this.reportsMap = Object.assign({}, this.reportsMap, {
        [id]: report
      });
      return report;
    }
    unregister(id) {
      this.reportsMap[id] = null;
    }
    registerChapter(reportId, chapterId) {
      let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      (0, _logger.log)("<service:document-data> registerChapter(".concat(reportId, ", ").concat(chapterId, ")"));
      let report = this.reportsMap[reportId];
      if (!report) return;
      let chapter = new _chapter.default({
        id: chapterId,
        index: report.chapterCount,
        startPage: report.chapterCount + 1,
        endPage: report.chapterCount + 1,
        name: opts.name,
        isToc: opts.isToc
      });
      report.addChapter(chapter);
      return chapter;
    }
    registerSection(reportId, chapterId, sectionId) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (0, _logger.log)("<service:document-data> registerSection(".concat(reportId, ", ").concat(chapterId, ", ").concat(sectionId, ")"));
      let {
        data,
        columnCount
      } = options;
      let report = this.reportsMap[reportId];
      let chapter = report.chapterMap[chapterId];
      let section = new _section.default({
        id: sectionId,
        data: data,
        columnCount: columnCount,
        index: chapter.sectionCount
      });
      chapter.sectionMap[sectionId] = section;
      chapter.sections.push(section);
      return section;
    }

    // Adds a page to a chapter
    addPage(reportId, chapterId) {
      (0, _logger.log)("<service:document-data> addPage(".concat(reportId, ", ").concat(chapterId, ")"));
      let report = this.reportsMap[reportId];
      let chapter = report.chapterMap[chapterId];
      let chapterIndex = report.chapters.indexOf(chapter);
      if (chapter.pages.length === 0) {
        chapter.pages = [new _page.default()];
        return;
      }
      chapter.endPage = chapter.endPage + 1;
      chapter.pages = [...chapter.pages, new _page.default({
        number: chapter.pages.length + 1
      })];
      for (let i = chapterIndex + 1; i < report.chapters.length; i++) {
        report.chapters[i].startPage = report.chapters[i].startPage + 1;
        report.chapters[i].endPage = report.chapters[i].endPage + 1;
      }
    }
  }
  _exports.default = DocumentData;
});