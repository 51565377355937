define("@frontile/forms/components/form-checkbox", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormField
    @size={{@size}}
    class={{use-frontile-class
      "form-checkbox"
      @size
      (if @checked "checked")
      class=(array @containerClass @privateContainerClass)
    }}
    as |f|
  >
    <div
      class={{use-frontile-class "form-checkbox" @size part="label-container"}}
    >
      <div
        class={{use-frontile-class "form-checkbox" @size part="input-container"}}
      >
        {{!  Zero-width space character, used to align checkbox properly }}
        ​
        <f.Checkbox
          @onChange={{this.handleChange}}
          @checked={{@checked}}
          @name={{@name}}
          class={{use-frontile-class "form-checkbox" @size part="checkbox"}}
          aria-describedby={{if @hint f.hintId}}
          ...attributes
        />
      </div>
  
      <f.Label class={{use-frontile-class "form-checkbox" @size part="label"}}>
        {{#if (has-block)}}
          {{yield}}
        {{else}}
          {{@label}}
        {{/if}}
      </f.Label>
    </div>
  
    {{#if @hint}}
      <f.Hint class={{use-frontile-class "form-checkbox" @size part="hint"}}>
        {{@hint}}
      </f.Hint>
    {{/if}}
  </FormField>
  */
  {
    "id": "XnIesFap",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[\"form-checkbox\",[30,1],[52,[30,2],\"checked\"]],[[\"class\"],[[28,[37,3],[[30,3],[30,4]],null]]]]]],[[\"@size\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[28,[37,1],[\"form-checkbox\",[30,1]],[[\"part\"],[\"label-container\"]]]],[12],[1,\"\\n    \"],[10,0],[15,0,[28,[37,1],[\"form-checkbox\",[30,1]],[[\"part\"],[\"input-container\"]]]],[12],[1,\"\\n\"],[1,\"      ​\\n      \"],[8,[30,5,[\"Checkbox\"]],[[16,0,[28,[37,1],[\"form-checkbox\",[30,1]],[[\"part\"],[\"checkbox\"]]]],[16,\"aria-describedby\",[52,[30,6],[30,5,[\"hintId\"]]]],[17,7]],[[\"@onChange\",\"@checked\",\"@name\"],[[30,0,[\"handleChange\"]],[30,2],[30,8]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[30,5,[\"Label\"]],[[16,0,[28,[37,1],[\"form-checkbox\",[30,1]],[[\"part\"],[\"label\"]]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,10]],[[[1,\"        \"],[18,10,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,9]],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,6],[[[1,\"    \"],[8,[30,5,[\"Hint\"]],[[16,0,[28,[37,1],[\"form-checkbox\",[30,1]],[[\"part\"],[\"hint\"]]]]],null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,6]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[5]]]]]],[\"@size\",\"@checked\",\"@containerClass\",\"@privateContainerClass\",\"f\",\"@hint\",\"&attrs\",\"@name\",\"@label\",\"&default\"],false,[\"form-field\",\"use-frontile-class\",\"if\",\"array\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/forms/components/form-checkbox.hbs",
    "isStrictMode": false
  });
  let FormCheckbox = _exports.default = (_class = class FormCheckbox extends _component2.default {
    handleChange(value, event) {
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
      if (typeof this.args._parentOnChange === 'function') {
        this.args._parentOnChange(value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormCheckbox);
});