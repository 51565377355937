define("ember-printable-pages/components/printable-pages/section", ["exports", "@ember/component", "@glimmer/component", "@ember/utils", "@ember/object", "@ember/object/internals", "ember-printable-pages/utils/logger", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _object, _internals, _logger, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.shouldRender this.page)}}
    {{! template-lint-disable no-inline-styles no-triple-curlies}}
    <div
      id={{this.elementId}}
      {{did-update this.onUpdate @data.length this.columnCount}}
      {{did-insert this.onInsert this}}
      {{will-destroy @renderNext}}
      class={{concat "PrintablePages-section " @sectionClass}}
      data-test-section
      {{style columnCount=(concat "" this.columnCount)}}
    >
      {{#if (and this.hasOnlyBlock this.page)}}
        {{yield (hash data=@item index=@index)}}
      {{else}}
        {{#each this.items as |item index|}}
          <PrintablePages::SectionItem
            @class={{@itemClass}}
            @section={{this.section}}
            @setLastRenderedItem={{@setLastRenderedItem}}
            @renderNext={{@renderNext}}
          >
            {{yield item (add index this.page.startIndex) index}}
          </PrintablePages::SectionItem>
        {{/each}}
      {{/if}}
    </div>
    {{! template-lint-enable no-inline-styles }}
  {{/if}}
  
  */
  {
    "id": "AkyBPwRx",
    "block": "[[[41,[28,[37,1],[[30,0,[\"shouldRender\"]],[30,0,[\"page\"]]],null],[[[1,\"  \"],[11,0],[16,1,[30,0,[\"elementId\"]]],[16,0,[28,[37,2],[\"PrintablePages-section \",[30,1]],null]],[24,\"data-test-section\",\"\"],[4,[38,3],[[30,0,[\"onUpdate\"]],[30,2,[\"length\"]],[30,0,[\"columnCount\"]]],null],[4,[38,4],[[30,0,[\"onInsert\"]],[30,0]],null],[4,[38,5],[[30,3]],null],[4,[38,6],null,[[\"columnCount\"],[[28,[37,2],[\"\",[30,0,[\"columnCount\"]]],null]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"hasOnlyBlock\"]],[30,0,[\"page\"]]],null],[[[1,\"      \"],[18,10,[[28,[37,8],null,[[\"data\",\"index\"],[[30,4],[30,5]]]]]],[1,\"\\n\"]],[]],[[[42,[28,[37,10],[[28,[37,10],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"        \"],[8,[39,11],null,[[\"@class\",\"@section\",\"@setLastRenderedItem\",\"@renderNext\"],[[30,8],[30,0,[\"section\"]],[30,9],[30,3]]],[[\"default\"],[[[[1,\"\\n          \"],[18,10,[[30,6],[28,[37,12],[[30,7],[30,0,[\"page\",\"startIndex\"]]],null],[30,7]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[6,7]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@sectionClass\",\"@data\",\"@renderNext\",\"@item\",\"@index\",\"item\",\"index\",\"@itemClass\",\"@setLastRenderedItem\",\"&default\"],false,[\"if\",\"and\",\"concat\",\"did-update\",\"did-insert\",\"will-destroy\",\"style\",\"yield\",\"hash\",\"each\",\"-track-array\",\"printable-pages/section-item\",\"add\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/section.hbs",
    "isStrictMode": false
  });
  let Section = _exports.default = (_class = class Section extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", "ember-" + (0, _internals.guidFor)(this));
      _defineProperty(this, "shouldRender", true);
      _defineProperty(this, "id", void 0);
      _defineProperty(this, "section", void 0);
      if (!this.shouldRender) return;
      this.id = this.args.registerSection({
        data: this.args.data || [],
        columnCount: this.columnCount
      });
      this.section = this.args.sectionMap[this.id];
    }
    get columnCount() {
      return Math.max(this.args.columnCount || 1, 1);
    }
    get hasOnlyBlock() {
      return (0, _utils.isEmpty)(this.args.data);
    }
    get page() {
      var _this$section;
      return (_this$section = this.section) === null || _this$section === void 0 || (_this$section = _this$section.pages) === null || _this$section === void 0 ? void 0 : _this$section.at(this.args.pageIndexInChapter);
    }
    get items() {
      return this.section.data.slice(this.page.startIndex, this.page.endIndex + 1);
    }
    onUpdate() {
      var _this$section2, _this$section3;
      let columnCountChanged = ((_this$section2 = this.section) === null || _this$section2 === void 0 ? void 0 : _this$section2.columnCount) != this.columnCount;
      let dataLengthChanged = this.args.data && ((_this$section3 = this.section) === null || _this$section3 === void 0 || (_this$section3 = _this$section3.data) === null || _this$section3 === void 0 ? void 0 : _this$section3.length) != this.args.data.length;
      if (this.shouldRender && (columnCountChanged || dataLengthChanged)) {
        (0, _logger.log)("%c <section:".concat(this.elementId, " - ").concat(this.id, "> did-update --- rerendering"), "color: grey");
        this.args.triggerRerender();
      }
    }
    onInsert() {
      if (this.hasOnlyBlock) {
        (0, _logger.log)("%c <section:".concat(this.elementId, " - ").concat(this.id, "> #onInsert -- has only block renderNext"), "color: grey");
        this.args.renderNext();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Section);
});