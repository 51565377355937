define("@frontile/buttons/components/button", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isRenderless}}
    {{yield (hash classNames=this.classNames)}}
  {{else}}
    <button
      type={{this.type}}
      class={{this.classNames}}
      ...attributes
    >
      {{yield}}
    </button>
  {{/if}}
  
  */
  {
    "id": "t+EviIB8",
    "block": "[[[41,[30,1],[[[1,\"  \"],[18,3,[[28,[37,2],null,[[\"classNames\"],[[30,0,[\"classNames\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,4,[30,0,[\"type\"]]],[16,0,[30,0,[\"classNames\"]]],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@isRenderless\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"hash\"]]",
    "moduleName": "@frontile/buttons/components/button.hbs",
    "isStrictMode": false
  });
  class Button extends _component2.default {
    get type() {
      if (this.args.type) {
        return this.args.type;
      }
      return 'button';
    }
    get classNames() {
      const names = [];
      if (this.args.appearance === 'outlined') {
        names.push('btn-outlined');
      } else if (this.args.appearance === 'minimal') {
        names.push('btn-minimal');
      } else if (this.args.appearance === 'custom') {
        names.push('btn-custom');
      } else {
        names.push('btn');
      }
      if (this.args.size) {
        names.push("".concat(names[0], "--").concat(this.args.size));
      }
      if (this.args.intent) {
        names.push("".concat(names[0], "--").concat(this.args.intent));
      }
      return names.join(' ');
    }
  }
  _exports.default = Button;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Button);
});