define("ember-printable-pages/components/printable-pages/title-page", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PrintablePages::PageLayout @pageLayout={{@pageLayout}}>
    <div class="PrintablePages-titlePage">
      {{yield (hash pageMeta=(hash total=@pageCount))}}
    </div>
  </PrintablePages::PageLayout>
  */
  {
    "id": "hETRbH/a",
    "block": "[[[8,[39,0],null,[[\"@pageLayout\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"PrintablePages-titlePage\"],[12],[1,\"\\n    \"],[18,3,[[28,[37,2],null,[[\"pageMeta\"],[[28,[37,2],null,[[\"total\"],[[30,2]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@pageLayout\",\"@pageCount\",\"&default\"],false,[\"printable-pages/page-layout\",\"yield\",\"hash\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/title-page.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});