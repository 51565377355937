define("ember-svg-jar/inlined/now-playing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect class=\"bar\" y=\"15\"/><rect class=\"bar\" transform=\"translate(25)\" y=\"15\"/><rect class=\"bar\" transform=\"translate(50)\" y=\"15\"/><rect class=\"bar\" transform=\"translate(75)\" y=\"15\"/><rect class=\"bar\" transform=\"translate(100)\" y=\"15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "now-playing-equalizer",
      "viewBox": "0 0 115 25"
    }
  };
});