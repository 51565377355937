define("ember-svg-jar/inlined/squiggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 1l7.41 3 7.377-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3 7.377-3h.171l7.411 3 7.377-3h.171l7.41 3 7.377-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3 7.377-3h.171l7.411 3 7.377-3h.171l7.41 3 7.377-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3 7.377-3h.171l7.411 3 7.376-3h.172l7.41 3L299 1\" stroke=\"#D2D2D2\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "299",
      "height": "5",
      "viewBox": "0 0 299 5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});