define("ember-printable-pages/components/printable-pages", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/runloop", "ember-concurrency", "rsvp", "@ember/object", "@ember/utils", "@glimmer/tracking", "@ember/object/internals", "ember-printable-pages/utils/logger", "@ember/test-waiters", "@ember/template-factory"], function (_exports, _component, _component2, _service, _runloop, _emberConcurrency, _rsvp, _object, _utils, _tracking, _internals, _logger, _testWaiters, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-update
      this.onUpdate
      @margins
      @dimensions
      @orientation
      @units
      @pageLayout
      @trackedForRefresh
    }}
    class="PrintablePages"
    id={{this.elementId}}
    ...attributes
  >
    {{#unless this.rerendering}}
      {{yield
        (hash
          title-page=(component
            "printable-pages/title-page"
            pageLayout=this.pageLayout
            pageCount=this.reportObject.lastPage
          )
  
          chapter=(component
            "printable-pages/chapter"
            chapters=this.chapters
            lastReportPage=this.reportObject.lastPage
            pageLayout=this.pageLayout
            registerChapter=this.registerChapter
            registerSection=(fn this.registerSection this.elementId)
            addPage=this.addPage
            checkIfComplete=(perform this.reportIfCompleteTask)
            triggerRerender=(perform this.rerenderTask)
          )
  
          table-of-contents=(component
            "printable-pages/table-of-contents"
            chapters=this.chapters
            lastReportPage=this.reportObject.lastPage
            pageLayout=this.pageLayout
            registerChapter=this.registerChapter
            registerSection=(fn this.registerSection this.elementId)
            addPage=this.addPage
            checkIfComplete=(perform this.reportIfCompleteTask)
            triggerRerender=(perform this.rerenderTask)
          )
        )
      }}
  
      <div {{did-insert this.addFirstPageToChapters}}></div>
    {{/unless}}
  </div>
  
  */
  {
    "id": "zOAsDXiW",
    "block": "[[[11,0],[24,0,\"PrintablePages\"],[16,1,[30,0,[\"elementId\"]]],[17,1],[4,[38,0],[[30,0,[\"onUpdate\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]],null],[12],[1,\"\\n\"],[41,[51,[30,0,[\"rerendering\"]]],[[[1,\"    \"],[18,8,[[28,[37,3],null,[[\"title-page\",\"chapter\",\"table-of-contents\"],[[50,\"printable-pages/title-page\",0,null,[[\"pageLayout\",\"pageCount\"],[[30,0,[\"pageLayout\"]],[30,0,[\"reportObject\",\"lastPage\"]]]]],[50,\"printable-pages/chapter\",0,null,[[\"chapters\",\"lastReportPage\",\"pageLayout\",\"registerChapter\",\"registerSection\",\"addPage\",\"checkIfComplete\",\"triggerRerender\"],[[30,0,[\"chapters\"]],[30,0,[\"reportObject\",\"lastPage\"]],[30,0,[\"pageLayout\"]],[30,0,[\"registerChapter\"]],[28,[37,5],[[30,0,[\"registerSection\"]],[30,0,[\"elementId\"]]],null],[30,0,[\"addPage\"]],[28,[37,6],[[30,0,[\"reportIfCompleteTask\"]]],null],[28,[37,6],[[30,0,[\"rerenderTask\"]]],null]]]],[50,\"printable-pages/table-of-contents\",0,null,[[\"chapters\",\"lastReportPage\",\"pageLayout\",\"registerChapter\",\"registerSection\",\"addPage\",\"checkIfComplete\",\"triggerRerender\"],[[30,0,[\"chapters\"]],[30,0,[\"reportObject\",\"lastPage\"]],[30,0,[\"pageLayout\"]],[30,0,[\"registerChapter\"]],[28,[37,5],[[30,0,[\"registerSection\"]],[30,0,[\"elementId\"]]],null],[30,0,[\"addPage\"]],[28,[37,6],[[30,0,[\"reportIfCompleteTask\"]]],null],[28,[37,6],[[30,0,[\"rerenderTask\"]]],null]]]]]]]]],[1,\"\\n\\n    \"],[11,0],[4,[38,7],[[30,0,[\"addFirstPageToChapters\"]]],null],[12],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@margins\",\"@dimensions\",\"@orientation\",\"@units\",\"@pageLayout\",\"@trackedForRefresh\",\"&default\"],false,[\"did-update\",\"unless\",\"yield\",\"hash\",\"component\",\"fn\",\"perform\",\"did-insert\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages.hbs",
    "isStrictMode": false
  });
  let waiter = (0, _testWaiters.buildWaiter)("render-waiter");
  let waiterToken;
  const DEFAULT_DIMENSIONS = {
    units: "in",
    dimensions: {
      width: 8.5,
      height: 11
    },
    margins: {
      top: 0.5,
      right: 0.5,
      bottom: 0.5,
      left: 0.5
    }
  };
  let getOrDefault = (context, namespace, key) => {
    let value = (0, _object.get)(context, "".concat(namespace, ".").concat(key));
    if ((0, _utils.isBlank)(value)) {
      return DEFAULT_DIMENSIONS[namespace][key];
    } else {
      return value;
    }
  };
  let PrintablePagesComponent = _exports.default = (_dec = (0, _emberConcurrency.task)({
    drop: true
  }), _dec2 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec3 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _dec4 = (0, _emberConcurrency.task)({
    keepLatest: true
  }), _class = class PrintablePagesComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", "ember-" + (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "documentData", _descriptor, this);
      _initializerDefineProperty(this, "rerendering", _descriptor2, this);
      _initializerDefineProperty(this, "reportObject", _descriptor3, this);
      _defineProperty(this, "element", void 0);
      this.renderTask.perform();
    }
    onInsert(element) {
      this.element = element;
    }
    get chapters() {
      var _this$reportObject;
      return (_this$reportObject = this.reportObject) === null || _this$reportObject === void 0 ? void 0 : _this$reportObject.chapters;
    }
    get pageLayout() {
      let units = (0, _utils.isBlank)(this.units) ? DEFAULT_DIMENSIONS.units : this.units;
      let width = getOrDefault(this.args, "dimensions", "width");
      let height = getOrDefault(this.args, "dimensions", "height");
      let top = getOrDefault(this.args, "margins", "top");
      let right = getOrDefault(this.args, "margins", "right");
      let bottom = getOrDefault(this.args, "margins", "bottom");
      let left = getOrDefault(this.args, "margins", "left");
      let innerWidth = width - right - left;
      let innerHeight = height - top - bottom;
      return {
        innerWidth: "".concat(innerWidth).concat(units),
        innerHeight: "".concat(innerHeight).concat(units),
        top: "".concat(top).concat(units),
        right: "".concat(right).concat(units),
        bottom: "".concat(bottom).concat(units),
        left: "".concat(left).concat(units)
      };
    }

    // TASKS
    // eslint-disable-next-line require-yield
    *renderTask() {
      this.reportObject = this.documentData.register(this.elementId);
      this.rerendering = false;
      this.reportStartTask.perform(this.reportObject.lastPage, null);
    }
    *rerenderTask() {
      (0, _logger.log)("<component:printable-pages> #rerenderTask");
      yield new _rsvp.Promise(resolve => {
        (0, _runloop.next)(() => {
          if (this.isDestroyed) return resolve();

          // Unregister, clear reportObject, clear the dom
          this.documentData.unregister(this.elementId);
          this.reportObject = null;
          this.rerendering = true;

          // Re-render after next render
          let rerender = () => {
            if (this.isDestroyed) return;
            this.renderTask.perform();
            resolve();
          };
          (0, _runloop.scheduleOnce)("afterRender", this, rerender);
        });
      });
    }
    *reportStartTask(currentPage) {
      if (waiterToken) waiter.endAsync(waiterToken);
      waiterToken = waiter.beginAsync();
      if (this.args.onRenderStart) {
        yield new _rsvp.Promise(resolve => {
          (0, _runloop.next)(() => {
            this.args.onRenderStart(currentPage);
            resolve();
          });
        });
      }
    }
    *reportProgressTask() {
      if (this.args.onRenderProgress) {
        yield new _rsvp.Promise(resolve => {
          (0, _runloop.next)(() => {
            var _this$reportObject2;
            this.args.onRenderProgress((_this$reportObject2 = this.reportObject) === null || _this$reportObject2 === void 0 ? void 0 : _this$reportObject2.lastPage);
            resolve();
          });
        });
      }
    }
    *reportIfCompleteTask() {
      var _this$reportObject3;
      if ((_this$reportObject3 = this.reportObject) !== null && _this$reportObject3 !== void 0 && _this$reportObject3.isFinishedRendering) {
        yield new _rsvp.Promise(resolve => {
          (0, _runloop.next)(() => {
            if (this.isDestroyed) return resolve();
            waiter.endAsync(waiterToken);
            if (this.args.onRenderComplete) {
              var _this$reportObject4;
              this.args.onRenderComplete((_this$reportObject4 = this.reportObject) === null || _this$reportObject4 === void 0 ? void 0 : _this$reportObject4.lastPage);
            }
            resolve();
          });
        });
      }
    }
    onUpdate() {
      this.rerenderTask.perform();
    }

    // ACTIONS
    registerChapter(id, opts) {
      return this.documentData.registerChapter(this.elementId, id, opts);
    }
    registerSection() {
      return this.documentData.registerSection(...arguments);
    }
    addPage(chapterId) {
      if (this.rerendering) return;
      this.documentData.addPage(this.elementId, chapterId);
      this.reportProgressTask.perform();
    }
    addFirstPageToChapters() {
      this.chapters.forEach(chapter => this.addPage(chapter.id));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "documentData", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rerendering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reportObject", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderTask", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "renderTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rerenderTask", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "rerenderTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reportStartTask", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "reportStartTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reportProgressTask", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "reportProgressTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reportIfCompleteTask", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "reportIfCompleteTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerChapter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerChapter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerSection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerSection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addFirstPageToChapters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addFirstPageToChapters"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PrintablePagesComponent);
});