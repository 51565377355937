define("ember-printable-pages/components/printable-pages/page-footer", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <div class="PrintablePages-pageFooter" data-test-page-footer>
      {{yield (hash pageMeta=@pageMeta)}}
    </div>
  {{/if}}
  */
  {
    "id": "5r13qirq",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[10,0],[14,0,\"PrintablePages-pageFooter\"],[14,\"data-test-page-footer\",\"\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"pageMeta\"],[[30,1]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@pageMeta\",\"&default\"],false,[\"if\",\"yield\",\"hash\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/page-footer.hbs",
    "isStrictMode": false
  });
  let PageFooter = _exports.default = (_class = class PageFooter extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "shouldRender", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldRender", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageFooter);
});