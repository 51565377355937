define("@frontile/changeset-form/components/changeset-form/fields/checkbox", ["exports", "@ember/component", "@frontile/changeset-form/components/changeset-form/fields/base", "@ember/object", "@ember/template-factory"], function (_exports, _component, _base, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <FormCheckbox
    @onChange={{this.handleChange}}
    @checked={{this.value}}
    @hint={{@hint}}
    @name={{@name}}
    @containerClass={{@containerClass}}
    @size={{@size}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@label}}
    {{/if}}
  </FormCheckbox>
  */
  {
    "id": "B/OD59zG",
    "block": "[[[8,[39,0],[[17,1]],[[\"@onChange\",\"@checked\",\"@hint\",\"@name\",\"@containerClass\",\"@size\"],[[30,0,[\"handleChange\"]],[30,0,[\"value\"]],[30,2],[30,3],[30,4],[30,5]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,6]],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@hint\",\"@name\",\"@containerClass\",\"@size\",\"@label\",\"&default\"],false,[\"form-checkbox\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@frontile/changeset-form/components/changeset-form/fields/checkbox.hbs",
    "isStrictMode": false
  });
  let ChangesetFormFieldsCheckbox = _exports.default = (_class = class ChangesetFormFieldsCheckbox extends _base.default {
    get fullFieldName() {
      return this.args._groupName ? "".concat(this.args._groupName, ".").concat(this.args.fieldName) : this.args.fieldName;
    }
    get value() {
      return this.args.changeset.get(this.fullFieldName);
    }
    get errors() {
      if (typeof this.args.errors !== 'undefined') {
        return this.args.errors;
      }
      const fieldErrors = this.args.changeset.errors.filter(error => {
        return error.key === this.fullFieldName;
      });
      return fieldErrors.reduce((errors, error) => {
        return [...errors, ...error.validation];
      }, []);
    }
    async validate() {
      await this.args.changeset.validate(this.fullFieldName);
    }
    async handleChange(value, event) {
      this.args.changeset.set(this.fullFieldName, value);
      await this.validate();
      if (typeof this.args._parentOnChange === 'function') {
        this.args._parentOnChange(value, event);
      }
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(value, event);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "validate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChangesetFormFieldsCheckbox);
});