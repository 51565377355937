define("ember-svg-jar/inlined/moon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "class": "w-6 h-6",
      "fill": "currentColor",
      "viewBox": "0 0 20 20"
    }
  };
});