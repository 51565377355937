define("ember-svg-jar/inlined/loader-pinwheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 12a1 1 0 01-10 0 1 1 0 00-10 0\"/><path d=\"M7 20.7a1 1 0 115-8.7 1 1 0 105-8.6\"/><path d=\"M7 3.3a1 1 0 115 8.6 1 1 0 105 8.6\"/><circle cx=\"12\" cy=\"12\" r=\"10\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "class": "lucide lucide-loader-pinwheel"
    }
  };
});