define("ember-svg-jar/inlined/pause-outline copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#000\" fill-rule=\"nonzero\"><path d=\"M54.3 180h-38C7.4 180 .1 172.7.1 163.8v-147C.1 7.9 7.4.6 16.3.6h38c8.9 0 16.2 7.3 16.2 16.2v147c0 8.9-7.3 16.2-16.2 16.2zm-38-167.4c-2.3 0-4.2 1.9-4.2 4.2v147c0 2.3 1.9 4.2 4.2 4.2h38c2.3 0 4.2-1.9 4.2-4.2v-147c0-2.3-1.9-4.2-4.2-4.2h-38zM150.6 180h-38c-8.9 0-16.2-7.3-16.2-16.2v-147c0-8.9 7.3-16.2 16.2-16.2h38c8.9 0 16.2 7.3 16.2 16.2v147c0 8.9-7.3 16.2-16.2 16.2zm-38-167.4c-2.3 0-4.2 1.9-4.2 4.2v147c0 2.3 1.9 4.2 4.2 4.2h38c2.3 0 4.2-1.9 4.2-4.2v-147c0-2.3-1.9-4.2-4.2-4.2h-38z\"/></g>",
    "attrs": {
      "width": "167",
      "height": "180",
      "viewBox": "0 0 167 180",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});