define("ember-printable-pages/util-models/section", ["exports", "@glimmer/tracking", "ember-printable-pages/util-models/page", "@ember/object", "ember-printable-pages/utils/logger"], function (_exports, _tracking, _page, _object, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Section = _exports.default = (_class = class Section {
    constructor() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      _initializerDefineProperty(this, "pages", _descriptor, this);
      _defineProperty(this, "id", void 0);
      _defineProperty(this, "columnCount", 1);
      _defineProperty(this, "nextItemIndex", 0);
      _defineProperty(this, "renderDataLength", 0);
      _defineProperty(this, "maxItemHeight", null);
      _defineProperty(this, "minItemHeight", null);
      _defineProperty(this, "isFullyRendered", false);
      _defineProperty(this, "data", []);
      let {
        id,
        index,
        columnCount,
        data
      } = options;
      this.id = id;
      this.index = index;
      this.columnCount = columnCount || 1;
      this.data = data;
    }
    get itemHeightDiff() {
      return this.maxItemHeight - this.minItemHeight;
    }
    itemCountForPage(pageIndex) {
      let page = this.pages.at(pageIndex);
      if (!page) return 0;
      return page.endIndex - page.startIndex + 1;
    }
    reconcilePageStartIndex(pageIndex) {
      let previousPage = this.pages.at(pageIndex - 1);
      let startIndex = previousPage.endIndex + 1;
      let page = this.pages.at(pageIndex);
      if (!page) {
        (0, _logger.log)("Section:".concat(this.id, " #reconcilePageStartIndex --- addPage"));
        page = this.addPage(pageIndex, startIndex);
      } else {
        page.startIndex = startIndex;
      }
      this.nextItemIndex = this.nextItemIndex + 1;
      (0, _logger.log)("Section:".concat(this.id, " #reconcilePageStartIndex"), "".concat(pageIndex, " : ").concat(page.startIndex));

      // this.updateIsFullyRendered();
    }

    // seems like this could be a getter
    updateIsFullyRendered() {
      (0, _logger.log)("Section:".concat(this.id, " #updateIsFullyRendered"), this.nextItemIndex >= this.data.length);
      this.isFullyRendered = this.nextItemIndex >= this.data.length;
    }
    addPage(pageIndex, startIndex) {
      (0, _logger.log)("Section:".concat(this.id, " #addPage"));
      let page = new _page.default({
        startIndex: startIndex,
        endIndex: startIndex
      });
      if (this.pages.length === 0) {
        this.pages = [...Array(pageIndex), page];
      } else {
        // When a chapter section, post-render, expands in height the content may
        // need to jump several pages forward. In that case fill the empty space
        // in the pages list for this section.
        this.pages = [...this.pages, ...Array(pageIndex - this.pages.length), page];
      }
      return page;
      // this.updateIsFullyRendered();
    }
    addItemToPage(pageIndex) {
      (0, _logger.log)("Section:".concat(this.id, " #addItemToPage"));
      let page = this.pages.at(pageIndex);
      if (!page) {
        this.addPage(pageIndex, 0);
      } else {
        page.endIndex = page.endIndex + 1;
        this.nextItemIndex = this.nextItemIndex + 1;
        // this.updateIsFullyRendered();
      }
    }
    toString() {
      return "<Models::Section:".concat(this.id, "> ").concat(this.data.length, " items, nextItemIndex ").concat(this.nextItemIndex, ", isFullyRendered ").concat(this.isFullyRendered);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "pages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addItemToPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addItemToPage"), _class.prototype), _class);
});