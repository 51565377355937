define("ember-svg-jar/inlined/volume-high-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#000\" fill-rule=\"nonzero\"><path d=\"M135.7 73.3V20c0-7.5-4.1-14.2-10.8-17.3-6.5-3.1-14.1-2.2-19.8 2.5L43.3 63.9H22.4C10.1 63.9.2 73.9.2 86.2v63.7c0 12.3 10 22.2 22.2 22.2h20.9l61.5 58.4.4.3c3.5 2.8 7.7 4.3 11.9 4.3 2.7 0 5.4-.6 8-1.8 6.6-3.2 10.8-9.8 10.8-17.3v-53.3c22-2.9 39.1-21.9 39.1-44.7-.2-22.8-17.3-41.8-39.3-44.7zM12.1 149.9V86.2c0-5.7 4.6-10.3 10.2-10.3h17.3v84.2H22.3c-5.6 0-10.2-4.6-10.2-10.2zM123.7 216c0 2.9-1.5 5.3-3.9 6.5-1.1.5-4 1.5-7-.8l-61.1-58.1v-91l61.1-58.1c3-2.3 5.8-1.4 7-.8 2.4 1.2 3.9 3.6 3.9 6.5V216zm12-65.4V85.5c15.4 2.8 27.1 16.4 27.1 32.6 0 16.2-11.7 29.7-27.1 32.5z\"/><path d=\"M153.3 193.5c-2.6 0-5-1.7-5.8-4.3-.9-3.2.9-6.5 4.1-7.4 28.2-8.2 47.9-34.4 47.9-63.7 0-29.4-19.7-55.6-47.9-63.7-3.2-.9-5-4.3-4.1-7.4.9-3.2 4.2-5 7.4-4.1 33.3 9.7 56.6 40.6 56.6 75.3 0 34.7-23.3 65.6-56.6 75.3h-1.6z\"/><path d=\"M154.3 231.8c-2.6 0-5.1-1.8-5.8-4.4-.9-3.2 1-6.5 4.2-7.4 48.8-13.1 82.8-55 82.8-102 0-46.9-34.1-88.9-82.8-102-3.2-.9-5.1-4.2-4.2-7.4.9-3.2 4.1-5.1 7.4-4.2 54 14.5 91.7 61.2 91.7 113.6 0 52.3-37.7 99-91.7 113.6-.6.1-1.1.2-1.6.2z\"/></g>",
    "attrs": {
      "width": "248",
      "height": "236",
      "viewBox": "0 0 248 236",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});