define("ember-svg-jar/inlined/volume-low-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#000\" fill-rule=\"nonzero\"><path d=\"M135.9 73V19.7c0-7.5-4.1-14.2-10.8-17.3-6.5-3.1-14.1-2.2-19.8 2.5L43.5 63.6H22.6C10.3 63.6.3 73.6.3 85.8v63.7c0 12.3 10 22.2 22.3 22.2h20.8l61.5 58.4.4.3c3.5 2.8 7.7 4.3 11.9 4.3 2.7 0 5.4-.6 8-1.8 6.6-3.2 10.8-9.8 10.8-17.3v-53.3c22-2.9 39.1-21.9 39.1-44.7-.2-22.8-17.2-41.7-39.2-44.6zm-96.1 86.8H22.5c-5.7 0-10.3-4.6-10.3-10.2V85.9c0-5.6 4.6-10.2 10.3-10.2h17.3v84.1zm84.1 55.8c0 2.9-1.5 5.3-3.9 6.5-1.1.6-4 1.5-7-.8l-61.2-58.1v-91L113 14c3-2.3 5.8-1.4 7-.8 2.4 1.2 3.9 3.6 3.9 6.5v195.9zm12-65.4V85.1c15.4 2.8 27.1 16.3 27.1 32.6-.1 16.2-11.8 29.7-27.1 32.5z\"/><path d=\"M153.5 193.2c-2.6 0-5-1.7-5.8-4.3-.9-3.2.9-6.5 4.1-7.4 28.2-8.2 47.9-34.4 47.9-63.7 0-29.4-19.7-55.6-47.9-63.7-3.2-.9-5-4.3-4.1-7.4.9-3.2 4.3-5 7.4-4.1 33.3 9.7 56.6 40.6 56.6 75.3 0 34.7-23.3 65.6-56.6 75.3-.5-.1-1.1 0-1.6 0z\"/></g>",
    "attrs": {
      "width": "212",
      "height": "235",
      "viewBox": "0 0 212 235",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});