define("ember-printable-pages/components/printable-pages/section-item", ["exports", "@ember/component", "@glimmer/component", "@ember/object/internals", "@ember/object", "ember-concurrency", "@ember/template-factory"], function (_exports, _component, _component2, _internals, _object, _emberConcurrency, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="PrintablePages-sectionItem"
    id={{this.elementId}}
    data-test-section-item
    {{did-insert this.onInsert this}}
    {{did-insert @renderNext this @section}}
    {{will-destroy this.willDestroy}}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "hVEdkLD6",
    "block": "[[[11,0],[24,0,\"PrintablePages-sectionItem\"],[16,1,[30,0,[\"elementId\"]]],[24,\"data-test-section-item\",\"\"],[17,1],[4,[38,0],[[30,0,[\"onInsert\"]],[30,0]],null],[4,[38,0],[[30,2],[30,0],[30,3]],null],[4,[38,1],[[30,0,[\"willDestroy\"]]],null],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@renderNext\",\"@section\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-printable-pages/components/printable-pages/section-item.hbs",
    "isStrictMode": false
  });
  let SectionItem = _exports.default = (_class = class SectionItem extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", "ember-" + (0, _internals.guidFor)(this));
      _defineProperty(this, "element", null);
    }
    onInsert(element) {
      this.element = element;
      this.onRender.perform();
      this.args.renderNext();
    }

    // eslint-disable-next-line require-yield
    *onRender() {
      let height = this.element.offsetHeight;
      if (this.args.section.maxItemHeight === null || this.args.section.maxItemHeight < height) {
        this.args.section.maxItemHeight = height;
      }
      if (this.args.section.minItemHeight === null || height < this.args.section.minItemHeight) {
        this.args.section.minItemHeight = height;
      }
      this.args.setLastRenderedItem(this.elementId);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.args.setLastRenderedItem("-" + this.elementId);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRender", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "onRender"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroy"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SectionItem);
});